<template>
  <div class="pbf">
     
     <BrandTabDiaries
      :inurl="'/diaries'"
      :category="'strain'"
      :strain-id="props.id"
      :intags="tags"
      />

  </div>
</template>

<script setup lang="ts">
 
import { TagsBrandProductDiaries } from '@/types/other'

interface Props {
  id: number
}

const props = defineProps<Props>()

const tags = ref(structuredClone(TagsBrandProductDiaries))


</script>



<style scoped>
 

</style>
